import React from 'react'
import useApi from '../hooks/useApi'
import { Empresa, User } from '../types'

export const UserLogin = async (email: string, senha: string) => {
  const api = useApi()
  return (await api.post('/auth/login', { email, senha })) as {
    data: User | null
    error: string | null
  }
}

export const CheckLogin = async () => {
  const api = useApi()
  return (await api.get('/auth/validate', {})) as {
    data: User | null
    error: string | null
  }
}

export const Logout = async () => {
  const api = useApi()
  return await api.post('/auth/logout', {})
}

export const Empresas = async () => {
  const api = useApi()
  return (await api.get('/auth/empresas', {})) as {
    data: Empresa[] | null
    error: string | null
  }
}
