import React from 'react'
import useApi from '../hooks/useApi'
import { IndicadoresVendas } from '../types'

export const GetVendas = async (
  dtInicial: string,
  dtFinal: string,
  empresaId?: string,
) => {
  const api = useApi()
  return (await api.get('/indicadores/vendas', {
    dtInicial,
    dtFinal,
    empresaId,
  })) as {
    data: IndicadoresVendas | null
    error: string | null
  }
}
