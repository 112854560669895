import React, { Component, Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { CSpinner } from '@coreui/react-pro'
import './scss/style.scss'
import ProtectRoute from './middlewares/RoutesProtect'
import { UserContextProvider } from './context/UserContext'
import { DataContextProvider } from './context/DataContext'

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const Login = React.lazy(() => import('./views/login/Login'))

class App extends Component {
  render(): JSX.Element {
    return (
      <BrowserRouter>
        <UserContextProvider>
          <DataContextProvider>
            <Suspense fallback={<CSpinner color="primary" />}>
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route element={<ProtectRoute />}>
                  <Route path="/*" element={<DefaultLayout />} />
                </Route>
              </Routes>
            </Suspense>
          </DataContextProvider>
        </UserContextProvider>
      </BrowserRouter>
    )
  }
}

export default App
