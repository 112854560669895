import React, { useCallback, useEffect, useState } from 'react'
import * as Service from '../services/Resources.Indicadores.Service'
import * as LoginService from '../services/Auth.Login.Service'
import { IndicadoresVendas } from '../types/Indicadores.Vendas.Type'
import { Empresa } from '../types'
import { debounce } from 'lodash'
import dayjs from 'dayjs'

type IDataContext = {
  data: IndicadoresVendas | null
  error: string | null
  loading: boolean
  empresaId?: string
  setEmpresaId: React.Dispatch<React.SetStateAction<string | undefined>>
  dtInicial: string
  setDtInicial: React.Dispatch<React.SetStateAction<string>>
  dtFinal: string
  setDtFinal: React.Dispatch<React.SetStateAction<string>>
  getEmpresas: () => Promise<void>
  empresas: Empresa[] | null
}

const DataContext = React.createContext<IDataContext | null>(null)

export const useData = () => {
  const context = React.useContext(DataContext)
  if (!context) throw new Error('useData precisa estar em DataContextProvider')
  return context
}

const getData = (n: number) => {
  const date = new Date()
  date.setDate(date.getDate() - n)
  const dd = String(date.getDate()).padStart(2, '0')
  const mm = String(date.getMonth() + 1).padStart(2, '0')
  const yyyy = date.getFullYear()
  return `${yyyy}-${mm}-${dd}`
}

export const DataContextProvider = ({ children }: React.PropsWithChildren) => {
  const [dtInicial, setDtInicial] = useState(getData(30))
  const [dtFinal, setDtFinal] = useState(getData(0))
  const [loading, setLoading] = useState(false)
  const [empresaId, setEmpresaId] = useState<string | undefined>(undefined)
  const [empresas, setEmpresas] = useState<Empresa[] | null>(null)
  const [error, setError] = useState<string | null>(null)
  const [data, setData] = useState<IndicadoresVendas | null>(null)
  const [dhSinc, setDhSinc] = useState<string | null>(null)

  const validateDate = (date?: string) => {
    const format = 'YYYY-MM-DD'
    return dayjs(date, format).format(format) === date
  }

  const search = async () => {
    await new Promise((resolve) => setTimeout(resolve, 300)) //remover posteriormente.
    if (!validateDate(dtInicial) || !validateDate(dtFinal)) {
      setData(null)
      setError('Data incorreta. Verifique.')
      setLoading(false)
      return
    }
    const res = await Service.GetVendas(dtInicial, dtFinal, empresaId)
    setLoading(false)
    setData(res.data)
    setError(res.error)
  }

  const debouncedGetData = useCallback(
    debounce(async () => {
      await search()
    }, 1500),
    [empresaId, dtInicial, dtFinal],
  )

  useEffect(() => {
    setData(null)
    setError(null)
    setLoading(true)
    if (empresaId) {
      debouncedGetData()
    }
  }, [empresaId, dtInicial, dtFinal])

  const getEmpresas = async () => {
    setLoading(true)
    const { data, error } = await LoginService.Empresas()
    setLoading(false)
    setEmpresas(data)
    setError(error)
    if (data && empresaId === undefined) {
      setEmpresaId(data?.[0].id)
    }
  }

  return (
    <DataContext.Provider
      value={{
        data,
        loading,
        error,
        dtInicial,
        setDtInicial,
        dtFinal,
        setDtFinal,
        empresaId,
        setEmpresaId,
        empresas,
        getEmpresas,
      }}
    >
      {children}
    </DataContext.Provider>
  )
}
