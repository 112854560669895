import axios, { AxiosRequestConfig } from 'axios'
import api from './axiosInstance'

type FecthApi = {
  data: any | null
  error: string | null
}

const RequestApi = async (
  url: string,
  options: AxiosRequestConfig,
): Promise<FecthApi> => {
  try {
    const res = await api({ url, ...options })

    if (res.status >= 200 && res.status < 400) {
      return { data: res.data, error: null }
    } else if (res.status >= 400 && res.status < 500) {
      return { data: null, error: res.data.error }
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return {
        data: null,
        error: error.message,
      }
    } else {
      return {
        data: null,
        error: 'Erro desconhecido.',
      }
    }
  }

  return {
    data: null,
    error: 'Sem dados.',
  }
}

const useApi = () => ({
  get: async (endpoint: string, params: any): Promise<FecthApi> => {
    return await RequestApi(endpoint, {
      method: 'get',
      params,
    })
  },
  post: async (endpoint: string, data: any): Promise<FecthApi> => {
    return await RequestApi(endpoint, { method: 'post', data })
  },
  put: async (
    endpoint: string,
    data: any,
    empresaId?: string,
  ): Promise<FecthApi> => {
    return await RequestApi(endpoint, {
      method: 'put',
      data,
      headers: { 'x-id': empresaId },
    })
  },
  delete: async (endpoint: string, data: any): Promise<FecthApi> => {
    return await RequestApi(endpoint, { method: 'delete', data })
  },
})

export default useApi
