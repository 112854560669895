import React, { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useUser } from '../context/UserContext'
import { useData } from '../context/DataContext'

//https://www.robinwieruch.de/react-router-private-routes/

const ProtectRoute = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { checkLogin } = useUser()
  const { getEmpresas } = useData()

  const checkAuth = async () => {
    const res = await checkLogin()
    if (!res) {
      navigate('/login', { replace: true, state: { from: location } })
    }
  }

  useEffect(() => {
    const execute = async () => {
      await checkAuth()
      await getEmpresas()
    }
    execute()
  }, [location.pathname])

  return <Outlet />
}

export default ProtectRoute
